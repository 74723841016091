import Vue from 'vue'
import Vuex from 'vuex'
import VueMq from 'vue-mq'
import App from './App.vue'
import router from './router'
import modalStore from './components/modalStore'
import '@/assets/js/swiped-events.min.js';

Vue.config.productionTip = false

Vue.use(Vuex);
Vue.use(VueMq, {
  breakpoints: {
    xs: 600,
    sm: 960,
    md: 1264,
    lg: 1904,
    xl: Infinity,
  },
  defaultBreakpoint: 'sm',
});

let store = new Vuex.Store({
  modules: {
    modalStore
  },
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
