<template>
    <div
        :class="[
            'vr-select',
            { 'vr-select--error': error },
            { 'vr-select--required': required },
            { 'vr-select--active': active },
            [`vr-select--${align}`],
            { 'vr-select--filled': filled },
        ]"
    >
        <button @click="active = !active" type="button" class="vr-select__dropdown">
            <span class="vr-select__dropdown__label">{{ message }}</span
            ><chevron-down-icon />
        </button>
        <vr-placeholder
            :required="required"
            :active="active"
            :filled="filled"
            :error="error"
            class="vr-select__placeholder"
            >{{ placeholder }}</vr-placeholder
        >
        <div class="vr-select__options">
            <ul>
                <li v-for="(option, idx) in options" :key="`option-${idx}`">
                    <input
                        @click="touched = true"
                        :id="`option-${idx + 1}`"
                        :value="option"
                        v-model="localValue"
                        type="checkbox"
                    />
                    <label :for="`option-${idx + 1}`"> {{ option }}</label>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import inputMixin from '@/mixins/input.mixin.js';
import ChevronDownIcon from 'icons/ChevronDown';

export default {
    name: 'VRSelect',
    mixins: [inputMixin],
    props: {
        //possible values: top, bottom
        align: {
            type: String,
            default: 'bottom',
        },
    },
    methods: {
        resetLocalValue() {
            this.localValue = [];
        },
    },
    computed: {
        // Overrides inputMixin computed - difference in localValue)
        filled() {
            return !!this.localValue.length;
        },
        checkedString() {
            const options = this.options;
            return (this.localValue || []).reduce((acc, item, idx) => {
                if (idx > 0 && idx < options.length) acc += ', ';
                return (acc += item);
            }, '');
        },
        message() {
            return this.errorMsg ? this.errorMsg : this.checkedString;
        },
    },
    components: {
        ChevronDownIcon,
    },
};
</script>
