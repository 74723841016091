<template>
	<div class="vr-number-input">
		<button class="vr-number-input__control" :disabled="value === minValue" @click="change(-1)"><minus-icon class="control__icon" /></button>
		<p class="vr-number-input__value">{{ value }}</p>
		<button class="vr-number-input__control" :disabled="value === maxValue" @click="change(1)"><plus-icon class="control__icon" /></button>
	</div>
</template>

<script>
import MinusIcon from "icons/Minus";
import PlusIcon from "icons/Plus";

export default {
	props: {
		value: {
			type: Number,
			default: 0
		},
		min: {
			type: [Number, String],
			default: -Infinity
		},
		max: {
			type: [Number, String],
			default: Infinity
		}
	},
	components: {
		MinusIcon,
		PlusIcon
	},
	computed: {
		minValue(){
			return (typeof this.min === 'string') ? parseInt(this.min, 10) : this.min;
		},
		maxValue(){
			return (typeof this.max === 'string') ? parseInt(this.max, 10) : this.max;
		}
	},
	methods: {
		change(delta){
			if(this.value + delta >= this.minValue && this.value + delta <= this.maxValue){
				this.$emit('input', this.value + delta);
			}
		}
	}
}
</script>