<template>
    <div
        :class="[
            'vr-input',
            { 'vr-input--error': error },
            { 'vr-input--required': required },
            { 'vr-input--active': active },
            { 'vr-input--filled': filled },
        ]"
    >
        <input
            :id="inputId"
            @focus="active = true"
            @blur="active = false"
            :value="localValue"
            @input="handleInput($event)"
            :type="type"
            :list="`${inputId}-datalist`"
            class="vr-input__input"
        />
        <vr-placeholder
            :required="required"
            :active="active"
            :filled="filled"
            :error="error"
            class="vr-input__placeholder"
            >{{ placeholder }}</vr-placeholder
        >
        <span class="vr-input__message">{{ errorMsg }}</span>
        <datalist v-if="options" :id="`${inputId}-datalist`" class="vr-input__datalist">
            <option v-for="(option, idx) in options" :key="`option-${idx}`" :value="option"></option>
        </datalist>
    </div>
</template>

<script>
import inputMixin from '@/mixins/input.mixin.js';
import { nanoid } from 'nanoid/non-secure';

export default {
    mixins: [inputMixin],
    data() {
        return {
            inputId: `input-${nanoid()}`,
        };
    },
    props: {
        // possible values: text, tel
        type: {
            type: String,
            default: 'text',
        },
    },
};
</script>
