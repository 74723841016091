var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
        'vr-button',
        `vr-button--${_vm.size}`,
        { 'vr-button--outlined': _vm.outlined },
        { 'vr-button--disabled': _vm.disabled },
        { 'vr-button--icon-left': _vm.iconPosition === 'left' },
        { 'vr-button--fullwidth': _vm.fullWidth },
    ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.handleClick($event)}}},[_vm._t("default"),(_vm.icon)?_c(_vm.chevron,{tag:"component",staticClass:"vr-button__icon"}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }