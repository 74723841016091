<template>
    <base-modal ref="baseModal" size="small" :align="!isSent ? 'top' : 'center'">
        <div class="modal-form">
            <template v-if="!isSent">
                <h3 class="modal-form__title">Запрос предложения</h3>
                <form @submit.prevent="submit" class="modal-form__form">
                    <vr-input
                        v-model="form.name.value"
                        @change="form.name.error = $event"
                        :required="form.name.required"
                        placeholder="Как к вам обращаться?"
                    />
                    <vr-input
                        v-model="form.phone.value"
                        @change="form.phone.error = $event"
                        :required="form.phone.required"
                        :setter="phoneSetter"
                        type="tel"
                        placeholder="Телефон"
                    />
                    <vr-input
                        v-model="form.email.value"
                        @change="form.email.error = $event"
                        :required="form.email.required"
                        placeholder="Электронная почта"
                    />
                    <vr-input
                        v-model="form.contact.value"
                        @change="form.contact.error = $event"
                        :required="form.contact.required"
                        placeholder="Предпочитаемый способ связи"
                    />
                    <vr-input
                        v-model="form.city.value"
                        @change="form.city.error = $event"
                        :required="form.city.required"
                        :options="cities"
                        placeholder="Город"
                    />
                    <vr-input
                        v-model="form.organisation.value"
                        @change="form.organisation.error = $event"
                        :required="form.organisation.required"
                        placeholder="Название организации"
                    />
                    <vr-input
                        v-model="form.position.value"
                        @change="form.position.error = $event"
                        :required="form.position.required"
                        placeholder="Должность"
                    />
                    <vr-select
                        v-model="form.products.value"
                        @change="form.products.error = $event"
                        :required="form.products.required"
                        :options="productsNames"
                        placeholder="Выберите продукты"
                    />
                    <vr-text-area
                        v-model="form.equipmentType.value"
                        @change="form.equipmentType.error = $event"
                        :required="form.equipmentType.required"
                        placeholder="Имеющееся оборудование"
                    />
                    <vr-input
                        v-model="form.equipmentNum.value"
                        @change="form.equipmentType.error = $event"
                        :required="form.equipmentType.required"
                        placeholder="Количество оборудования"
                    />
                    <vr-text-area
                        v-model="form.comments.value"
                        @change="form.comments.error = $event"
                        :required="form.comments.required"
                        placeholder="Комментарий"
                    />
                    <vr-checkbox
                        v-model="form.requiredDemo.value"
                        @change="form.requiredDemo.error = $event"
                        :required="form.requiredDemo.required"
                        placeholder="Нужна демо-версия"
                    />
                    <vr-button :disabled="disabled" type="submit">Отправить</vr-button>
                    <p class="modal-form__form__agreement">
                        Нажимая на кнопку «Отправить», Вы соглашаетесь с
                        <router-link :to="{ name: 'privacy' }">политикой обработки персональных данных.</router-link>
                    </p>
                </form>
            </template>
            <template v-else>
                <p class="modal-form__text">Ваш запрос отправлен.</p>
                <p class="modal-form__text">Мы с вами скоро свяжемся.</p>
            </template>
        </div>
    </base-modal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue';
import VRInput from '@/components/inputs/VRInput.vue';
import VRSelect from '@/components/inputs/VRSelect.vue';
import VRTextArea from '@/components/inputs/VRTextArea.vue';
import VRCheckbox from '@/components/inputs/VRCheckbox.vue';
import VRButton from '@/components/buttons/VRButton.vue';
import productsMixin from '@/mixins/products.mixin.js';
import citiesMixin from '@/mixins/cities.mixin.js';
import { submitFeedback } from '@/api';

export default {
    mixins: [productsMixin, citiesMixin],
    data() {
        return {
            form: {
                name: {
                    value: null,
                    required: true,
                    error: false,
                },
                phone: {
                    value: null,
                    required: true,
                    error: false,
                },
                email: {
                    value: null,
                    required: true,
                    error: false,
                },
                contact: {
                    value: null,
                    required: true,
                    error: false,
                },
                city: {
                    value: null,
                    required: true,
                    error: false,
                },
                organisation: {
                    value: null,
                    required: true,
                    error: false,
                },
                position: {
                    value: null,
                    required: true,
                    error: false,
                },
                products: {
                    value: [],
                    required: true,
                    error: false,
                },
                equipmentType: {
                    value: null,
                    required: false,
                    error: false,
                },
                equipmentNum: {
                    value: null,
                    required: false,
                    error: false,
                },
                comments: {
                    value: null,
                    required: false,
                    error: false,
                },
                requiredDemo: {
                    value: false,
                    required: false,
                    error: false,
                },
            },
            isSent: false,
            commentsValue: null,
            productsNames: null,
        };
    },
    components: {
        'base-modal': BaseModal,
        'vr-input': VRInput,
        'vr-select': VRSelect,
        'vr-text-area': VRTextArea,
        'vr-checkbox': VRCheckbox,
        'vr-button': VRButton,
    },
    methods: {
        handle(event) {
            this.form.phone = event;
        },
        async submit() {
            if (!this.disabled) {
                try {
                    await submitFeedback(this.form);
                    this.isSent = true;
                } catch (err) {
                    console.error(err);
                }
            }
        },
        showModal(initial) {
            if (initial) {
                for (let key in initial) {
                    if (this.form[key]) this.form[key].value = initial[key];
                }
            }
            this.$refs.baseModal.showModal();
        },
        phoneSetter(val) {
            let value = val;
            const cleanValue = value.replace(/[^\d+]/g, '');
            if (cleanValue.match(/^[8+]/)) {
                value = cleanValue;
            } else {
                if (cleanValue.startsWith('7')) value = '+' + cleanValue;
                else value = cleanValue ? '+7' + cleanValue : '';
            }
            return value;
        },
    },
    computed: {
        disabled() {
            let disabled = false;
            for (let key in this.form) {
                const valueIsEmpty = Array.isArray(this.form[key].value)
                    ? this.form[key].value.length === 0
                    : !this.form[key].value;
                if ((valueIsEmpty && this.form[key].required) || this.form[key].error) disabled = true;
            }
            return disabled;
        },
    },
    mounted() {
        this.$root.$on('closeModal', () => {
            this.isSent = false;
            for (let key in this.form) {
                if (Array.isArray(this.form[key].value)) {
                    this.form[key].value = [];
                    continue;
                }
                if (typeof this.form[key].value === 'boolean') {
                    this.form[key].value = false;
                    continue;
                }
                this.form[key].value = null;
            }
        });

        this.productsNames = this.products.reduce((acc, current) => {
            acc.push(current.name);
            return acc;
        }, []);
    },
};
</script>
