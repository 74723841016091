<template>
    <div class="services-page">
        <h1 class="page-title">Услуги</h1>
        <div class="page-hero">
            <img src="@/assets/img/views/services/study-room.jpg" />
        </div>
        <section class="page-section">
            <h2 class="section__title">Проектиро&shy;вание VR&#8209;кабинетов</h2>
            <p class="section__text">
                Поможем подобрать VR-оборудование под ваши нужды и возможности. Поможем написать техническое задание для
                закупки исходя из запросов Заказчика. Оказание услуги начинается с первичной консультации длиной 45
                минут.
            </p>
            <p class="services-page__price-request">
                Цена по запросу на <a href="mailto:eduvr@vrnti.ru">eduvr@vrnti.ru</a>
            </p>
        </section>
        <section class="page-section page-section--light services-page__education">
            <img class="education__image" src="@/assets/img/views/services/education.jpg" />
            <h2 class="section__title">Организация обучения</h2>
            <p class="section__text">
                Бесплатно подберем ближайший к Заказчику партнерский центр, оказывающий соответствующие услуги и имеющий
                лицензию на использование VR-приложений центра. Бесплатно порекомендуем дистанционные или очные курсы по
                использованию VR в преподавании.
            </p>
        </section>
        <section class="page-section services-page__equipment">
            <h2 class="section__title">Аренда оборудования</h2>
            <p class="section__text">
                Предоставление оборудования и программного обеспечения для выездных занятий с использованием VR. В
                зависимости от запроса и географического положения Заказчика цена может меняться.
            </p>
            <vr-button @click="openServiceModal" class="services-page__equipment__btn">Связаться</vr-button>
        </section>
        <div class="page-hero">
            <img src="@/assets/img/views/services/equipment.jpg" />
        </div>
        <service-modal ref="serviceModal" size="medium" />
    </div>
</template>

<script>
import VRButton from '@/components/buttons/VRButton.vue';
import ServiceModal from '@/components/modals/ServiceModal.vue';

export default {
    methods: {
        openServiceModal() {
            this.$refs.serviceModal.showModal();
        },
    },
    components: {
        'vr-button': VRButton,
        'service-modal': ServiceModal,
    },
};
</script>
