<template>
    <div class="products-page page__content">
        <h1 class="products-page__title">Наши продукты</h1>
        <div class="products-page__cards">
            <vr-large-product-card
                v-for="(product, idx) in products"
                :key="`grid-item-${idx}`"
                :product="product"
                :dark="product.dark"
            />
        </div>
    </div>
</template>

<script>
import VRLargeProductCard from '@/components/cards/VRLargeProductCard.vue';
import productsMixin from '@/mixins/products.mixin.js';

export default {
    mixins: [productsMixin],
    components: {
        'vr-large-product-card': VRLargeProductCard,
    },
};
</script>
