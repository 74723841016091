var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'vr-textarea',
        { 'vr-textarea--error': _vm.error },
        { 'vr-textarea--required': _vm.required },
        { 'vr-textarea--active': _vm.active },
        { 'vr-textarea--filled': _vm.filled },
    ]},[_c('textarea',{staticClass:"vr-textarea__textarea",attrs:{"rows":_vm.rows},domProps:{"value":_vm.localValue},on:{"focus":function($event){_vm.active = true},"blur":function($event){_vm.active = false},"input":function($event){return _vm.handleInput($event)}}}),_c('vr-placeholder',{staticClass:"vr-textarea__placeholder",attrs:{"required":_vm.required,"active":_vm.active,"filled":_vm.filled,"error":_vm.error}},[_vm._v(_vm._s(_vm.placeholder))]),_c('span',{staticClass:"vr-textarea__message"},[_vm._v(_vm._s(_vm.errorMsg))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }