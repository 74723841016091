<template>
    <div class="product-page vr-chem-lab-page">
        <h1 class="page-title">VR Space</h1>
        <div class="page-hero">
            <iframe
                src="https://vk.com/video_ext.php?oid=-200137374&id=456239031&hash=32aefaa916d34aef&hd=2"
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                frameborder="0"
                allowfullscreen
            ></iframe>
        </div>
        <section class="page-section">
            <h2 class="section__title">VR Space</h2>
            <p class="section__text">
                Это экспериментально-практический курс по стереометрии. Тренажер подойдет для дополнительного
                образования в 7 – 9 классах, для подготовки к освоению стереометрии.
            </p>
            <p class="section__text">
                Курс строится на деятельностном подходе. Внутри серии задач совершаются переходы от решения несложных
                задач, которые ориентируются на получение ответа любым способом, до исследования границ применимости
                метода для данного типа задач.
            </p>
            <p class="section__text">
                Для учеников разработаны задания в виртуальной реальности и большой набор задач по разным темам, который
                не требует VR-оборудования, его можно распечатать на бумаге.
            </p>
            <p class="section__text">Приложение разработано в сотрудничестве с компанией «Мастерская науки»</p>
        </section>
        <section class="page-section page-section--dark page-section--no-padding">
            <h2 class="section__title">Фотогалерея</h2>
            <vr-slider class="product-page__gallery" :items="gallery">
                <template #default="{ item, keyValue }">
                    <div class="gallery__item" :key="keyValue">
                        <img class="item__image" :src="item.url" />
                    </div>
                </template>
            </vr-slider>
        </section>
        <section class="page-section page-section--light vr-chem-lab-page__section">
            <h2 class="section__title">{{ title }}:</h2>
            <ol class="section__ordered-list">
                <li v-for="(item, idx) in list" :key="`list-item-${idx}`" class="ordered-list__item">
                    {{ item }}
                </li>
            </ol>
        </section>
        <section class="page-section">
            <h2 class="section__title">Технические требования</h2>
            <p>Для стабильной работы приложения требуется:</p>
            <ul>
                <li>«VR Ready» компьютер</li>
                <li>стационарный шлем виртуальной реальности</li>
                <li>подключение к интернету на скорости не менее 10&nbsp;МБит&nbsp;/&nbsp;с</li>
            </ul>
            <p>либо</p>
            <ul>
                <li>автономный шлем виртуальной реальности</li>
                <li>подключение к интернету на скорости не менее 10&nbsp;МБит&nbsp;/&nbsp;с</li>
            </ul>
            <p>Список поддерживаемых шлемов виртуальной реальности приведен в таблице.</p>
            <table class="section__table">
                <tbody>
                    <tr>
                        <td>Список стационарных шлемов</td>
                        <td>Список автономных шлемов</td>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li v-for="(headset, idx) in stationaryHeadsets" :key="`stationaryHeadset-${idx}`">
                                    {{ headset }}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li v-for="(headset, idx) in standaloneHeadset" :key="`standaloneHeadset-${idx}`">
                                    {{ headset }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
        <section class="page-section page-section--dark">
            <h2 class="section__title">Цена продукта</h2>
            <product-calculator :licencesData="licencesData" class="product-page__calculator" />
        </section>
        <section class="page-section">
            <h2 class="section__title">Подробнее о продукте</h2>
            <a class="section__link" href="https://reestr.digital.gov.ru/reestr/745532/?sphrase_id=1685374" target="_blank"
                >+ Ссылка на реестр российского ПО</a
            >
            <a class="section__link" :href="require('@/assets/img/views/vr-space/vrspace-rao.jpg')" target="_blank"
                >+ Заключение РАО</a
            >
            <a class="section__link" :href="require('@/assets/pdf/VR Space.pdf')" target="_blank"
                >+ Печатные материалы о продукте</a
            >
        </section>
    </div>
</template>

<script>
import VRSlider from '@/components/VRSlider';
import ProductCalculator from '@/components/ProductCalculator';

export default {
    data() {
        return {
            gallery: Array.from({ length: 5 }).map((_, idx) => ({
                url: require(`@/assets/img/views/vr-space/frame${idx + 1}.jpg`),
            })),
            title: 'В VR-модуль входят, такие задания как',
            list: [
                'Укладка прямоугольников в развертку цилиндра',
                'Размещение деталей различной формы в контейнере',
                'Построение (либо вычисление объёма) пространственных фигур согласно проекциям',
                'Определение вида зависимости высоты уровня жидкости в сосуде от форма сосуда и объёма жидкости, налитой в него',
            ],
            licencesData: [
                {
                    key: 'unlimited',
                    period: '"вечная"',
                    price: 40500,
                    prices: [
                        {
                            start: 0,
                            end: Infinity,
                            price: 40500,
                        },
                    ],
                },
            ],
            stationaryHeadsets: ['HTC Vive / Vive Pro / Vive Pro Eye / Vive Pro 2', 'HTC Vive Cosmos', 'HTC Vive Cosmos Elite', 'Oculus Rift', 'HP Reverb', 'Dell visor', 'Samsung HMD Odyssey+', 'Acer AH101'],
            standaloneHeadset: ['Focus plus', 'Pico Neo 3', 'Pico 4'],
        };
    },
    components: {
        ProductCalculator,
        'vr-slider': VRSlider,
    },
};
</script>