<template>
  <router-link :to="{name: product.pathName}" class="vr-product-card">
    <div
      :class="{
        'vr-product-card__container': true,
        'vr-product-card__container--dark': dark,
      }"
      :style="coverUrl"
    >
      <h5 class="vr-product-card__container__title">
        {{ product.name }}
      </h5>
      <p class="vr-product-card__container__text">
        {{ product.text }}
      </p>
      <span class="vr-product-card__container__span">
        Узнать больше <chevron-down
      /></span>
    </div>
  </router-link>
</template>

<script>
import ChevronDown from "icons/ChevronDown";

export default {
  props: {
    product: {
      type: Object,
      default: () => ({
        name: "",
        text: "",
        desktopCoverUrl: "",
        pathName: "#",
      }),
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ChevronDown,
  },
  computed: {
    coverUrl() {
      return { backgroundImage: `url(${this.product.desktopCoverUrl})` };
    },
  },
};
</script>