<template>
    <div class="about-page">
        <h1 class="page-title">О компании</h1>
        <div class="page-hero">
            <img src="@/assets/img/common/collage.jpg" />
        </div>
        <section class="page-section about-page__dvfu">
            <h2 class="section__title">Центр НТИ ДВФУ</h2>
            <p class="section__text">
                Центр компетенций НТИ в области технологии виртуальной и дополненной реальности создан на базе
                Дальневосточного федерального университета.
            </p>

            <p class="section__text">
                В 2020/2021 учебном году была создана
                <a href="https://edu.vrnti.ru/platform/start" target="_blank" rel="noopener noreferrer"
                    >платформа образовательных VR/AR решений для школ</a
                >
                , а также подготовлена и проведена
                <a href="https://edu.vrnti.ru/approbation" target="_blank" rel="noopener noreferrer"
                    >Всероссийская программа апробации образовательного ПО для виртуальной и дополненной реальности.</a
                >
            </p>

            <p class="section__text">
                В рамках направления «Образование» Центр исследует, разрабатывает и внедряет инновационные решения с
                применением технологий VR/AR для повышения качества образования, разрабатывает программы повышения
                квалификации для педагогов, готовит обучающие материалы для них и занимается развитием сообщества
                преподавателей.
            </p>

            <p class="section__text">
                Центр в сотрудничестве с компаниями «Мастерская науки», «СТЕМ-игры», «Модум Лаб» и «Виар Суперсоник»
                разрабатывает образовательные приложения, в том числе VR Space, VR Chemistry Lab, Физика.Магнетизм и
                VARVARA. Все приложения созданы российскими разработчиками и являются полностью российским программным
                обеспечением.
            </p>

            <p class="section__text">
                Коммерческую деятельность от лица Центра ведет ООО «Центр Технологий Виртуальной и Дополненной
                Реальности в Сфере Образования».
            </p>
        </section>
        <section class="page-section page-section--light about-page__contacts">
            <h2 class="section__title">Контакты</h2>
            <div class="contacts__social">
                <a target="_blank" rel="noopener noreferrer" href="https://t.me/virtualliteracy01" class="social__link">
                    <img src="@/assets/img/logo/telegram-logo.png" alt="логотип телеграм" />
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://vk.com/educationvrar" class="social__link">
                    <img src="@/assets/img/logo/vk-logo.png" alt="логотип вконтакте" />
                </a>
            </div>
            <p class="contacts__text contacts__text--sales">По вопросам приобретения и сотрудничества</p>
            <p class="contacts__text contacts__text--support">По вопросам технической поддержки</p>
            <p class="contacts__email contacts__email--sales">eduvr@vrnti.ru</p>
            <p class="contacts__email contacts__email--support">support@vrnti.ru</p>
        </section>
        <section class="page-section">
            <h2 class="section__title">Адреса</h2>
            <p class="section__text">
                По предварительной записи (свяжитесь с нами по указанным контактам) возможность посмотреть наши продукты
                на нашем или партнерском VR-оборудовании существует в следующих городах:
            </p>
            <div class="about-page__map">
                <div class="map__buttons">
                    <button
                        class="map__button"
                        v-for="location in locations"
                        @click="currentLocation = location"
                        :class="{ active: currentLocation === location }"
                        :key="location.url"
                    >
                        {{ location.city }}
                    </button>
                    <button class="map__button map__button--info" @click.stop="popupVisible = !popupVisible">
                        <info-icon />
                    </button>
                    <transition name="popup-fade">
                        <div ref="popup" class="map__popup" v-show="popupVisible">
                            <p>Список городов может быть расширен.</p>
                            <p>
                                Если вы хотите стать площадкой показа демоверсий VR-приложений Центра, напишите нам на
                                <a class="popup__email" href="mailto:eduvr@vrnti.ru">eduvr@vrnti.ru</a>
                            </p>
                        </div>
                    </transition>
                </div>
                <div class="map__location-description">
                    <p>{{ currentLocation.description }}</p>
                </div>
                <iframe class="map__widget" :src="currentLocation.url" allowfullscreen="true"></iframe>
            </div>
        </section>
        <section class="page-section page-section--light about-page__legal">
            <h2 class="section__title">Данные о юридическом лице:</h2>
            <p class="section__text">ООО "Центр Технологий Виртуальной и Дополненной Реальности в Сфере Образования"</p>
            <p class="section__text"><strong>ИНН:</strong> 9703050509, <strong>ОГРН:</strong> 1217700461065.</p>
            <p class="section__text">
                <strong>Юр. адрес:</strong> 123112, город Москва, Пресненская наб, д. 12, ком. а30 помещ. 2.1
            </p>
            <p class="section__text"><strong>Электронная почта:</strong> eduvr@vrnti.ru</p>
        </section>
    </div>
</template>

<script>
import InfoIcon from 'icons/InformationOutline';

const LOCATIONS = [
    {
        city: 'Владивосток',
        description: 'Кампус Дальневосточного Федерального университета',
        url: 'https://yandex.ru/map-widget/v1/-/CCUBZRgh8A',
    },
    {
        city: 'Москва',
        description: 'ул. Ярцевская д. 10',
        url: 'https://yandex.ru/map-widget/v1/-/CCUFJKFkOD',
    },
];

export default {
    data() {
        return {
            locations: LOCATIONS,
            currentLocation: LOCATIONS[0],
            popupVisible: false,
        };
    },
    components: {
        InfoIcon,
    },
    methods: {
        handleClickOutside(ev) {
            if (this.popupVisible && !this.$refs.popup.contains(ev.target)) {
                this.popupVisible = false;
            }
        },
    },
    mounted() {
        window.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        window.removeEventListener('click', this.handleClickOutside);
    },
};
</script>
