<template>
    <span
        :class="[
            'vr-placeholder',
            { 'vr-placeholder--required': required },
            { 'vr-placeholder--active': active },
            { 'vr-placeholder--filled': filled },
            { 'vr-placeholder--error': error },
        ]"
        ><slot />
    </span>
</template>

<script>
export default {
    props: {
        required: {
            type: Boolean,
            default: false,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
            required: true,
        },
        filled: {
            type: Boolean,
            default: false,
            required: true,
        },
        error: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
};
</script>
