const BACKGROUND = "rgba(0, 0, 0, 0.8)"

const state = {
  modalWindowActive: false,
  newsModalWindow: { background: BACKGROUND, is_active: false, image: null, date: "", title: "", text: ""},
};

const getters = {
    newsModalWindow: (state) => state.newsModalWindow,
};

const actions = {
  tryActivateNewsModalWindow ({ commit, getters }, { background }) {
    if (getters.modalWindowActive) {
      return false
    }
    commit("activateNewsModalWindow", { background: background ? background: BACKGROUND })
    commit("_toggleModalWindow", true)
    return true
  },
  tryDeactivateNewsModalWindow ({ commit, getters }) {
      const newsModalWindow = getters.newsModalWindow;
      if (!newsModalWindow.is_active) {
          return false
      } 
      commit("deactivateNewsModalWindow")
      commit("_toggleModalWindow", false)
      return true
  },
};

const mutations = {
  activateNewsModalWindow: (state, { background }) => {
    state.newsModalWindow = {  ...state.newsModalWindow, background, is_active: true}
  },
  deactivateNewsModalWindow: (state) => {
    state.newsModalWindow = { ...state.newsModalWindow, is_active: false }
  },
  _toggleModalWindow: (state, active) => state.modalWindowActive = active,
  updateTitle: (state, title) => {
    state.newsModalWindow.title = title;
  },
  updateText: (state, text) => {
    state.newsModalWindow.text = text;
  },
  updateImage: (state, image) => {
    state.newsModalWindow.image = image;
  },
  updateDate: (state, date) => {
    state.newsModalWindow.date = date;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}