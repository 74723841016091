<template>
    <router-link :to="{name: product.pathName}" :class="['vr-slider-card', {'vr-slider-card--dark': dark}]">
        <div class="vr-slider-card__container" :style="coverUrl">
            <h1 class="vr-slider-card__container__title">{{ product.name }}</h1>
            <p class="vr-slider-card__container__text">
                {{ product.text }}
            </p>
            <vr-button icon class="vr-slider-card__container__btn">Подробнее</vr-button>
        </div>
    </router-link>
</template>

<script>
import VRButton from '@/components/buttons/VRButton.vue';
import deviceMixin from "@/mixins/device.mixin.js";

export default {
    mixins: [deviceMixin],
    data() {
        return {
            counter: 0,
        };
    },
    props: {
        product: {
            type: Object,
            default: () => ({
                id: 0,
                name: '',
                text: '',
                desktopCoverUrl: '',
                mobileCoverUrl: '',
                pathName: '#',
            }),
        },
        dark: {
            type: Boolean,
            default: false
        }
    },
    components: {
        'vr-button': VRButton,
    },
    computed: {
        coverUrl() {
            if (this.$mq === 'xs') return { backgroundImage: `url(${this.product.mobileCoverUrl})` };
            return { backgroundImage: `url(${this.product.desktopCoverUrl})` };
        },
    },
};
</script>