var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'vr-select',
        { 'vr-select--error': _vm.error },
        { 'vr-select--required': _vm.required },
        { 'vr-select--active': _vm.active },
        [`vr-select--${_vm.align}`],
        { 'vr-select--filled': _vm.filled },
    ]},[_c('button',{staticClass:"vr-select__dropdown",attrs:{"type":"button"},on:{"click":function($event){_vm.active = !_vm.active}}},[_c('span',{staticClass:"vr-select__dropdown__label"},[_vm._v(_vm._s(_vm.message))]),_c('chevron-down-icon')],1),_c('vr-placeholder',{staticClass:"vr-select__placeholder",attrs:{"required":_vm.required,"active":_vm.active,"filled":_vm.filled,"error":_vm.error}},[_vm._v(_vm._s(_vm.placeholder))]),_c('div',{staticClass:"vr-select__options"},[_c('ul',_vm._l((_vm.options),function(option,idx){return _c('li',{key:`option-${idx}`},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],attrs:{"id":`option-${idx + 1}`,"type":"checkbox"},domProps:{"value":option,"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,option)>-1:(_vm.localValue)},on:{"click":function($event){_vm.touched = true},"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}}),_c('label',{attrs:{"for":`option-${idx + 1}`}},[_vm._v(" "+_vm._s(option))])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }