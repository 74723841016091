// mixin stores base info about products that is used in such components as product cards, modal windows, etc.
export default {
    data: () => ({
        products: [
            {
                name: 'VR Chemistry Lab',
                text: 'VR химическая лаборатория c модулем расчета химически корректного ответа на любые действия пользователя и конструктор заданий для учителя.',
                desktopCoverUrl: require('@/assets/img/components/cards/vr-chemistry-lab-desktop.jpg'),
                mobileCoverUrl: require('@/assets/img/components/cards/vr-chemistry-lab-mobile.jpg'),
                pathName: 'vr-chem-lab',
                dark: true,
            },
            {
                name: 'VARVARA',
                text: 'Диалоговый тренажер английского языка для языковой практики на начальном уровне изучения.',
                desktopCoverUrl: require('@/assets/img/components/cards/varvara-desktop.jpg'),
                mobileCoverUrl: require('@/assets/img/components/cards/varvara-mobile.jpg'),
                pathName: 'varvara',
                dark: false,
            },
            {
                name: 'VR Space',
                text: 'Практический курс для пропедевтики стереометрии. VR-приложение и методические материалы для работы без VR.',
                desktopCoverUrl: require('@/assets/img/components/cards/vr-space-desktop.jpg'),
                mobileCoverUrl: require('@/assets/img/components/cards/vr-space-mobile.jpg'),
                pathName: 'vr-space',
                dark: true,
            },
            {
                name: 'Физика.Магнетизм',
                text: 'Интерактивный VR учебник по теме «Магнетизм» с теоретическими блоками и практическими заданиями.',
                desktopCoverUrl: require('@/assets/img/components/cards/magnetism-desktop.jpg'),
                mobileCoverUrl: require('@/assets/img/components/cards/magnetism-mobile.jpg'),
                pathName: 'magnetism',
                dark: true,
            },

        ],
    }),
};
