<template>
    <div class="main-page">
        <feedback-modal ref="feedbackModal" size="medium" />
        <vr-slider v-if="products.length > 0" :items="products" navigation class="main-page__courses-slider">
            <template #default="{ item, keyValue }">
                <vr-slider-card :product="item" :dark="item.dark" :key="keyValue" />
            </template>
        </vr-slider>

        <section class="main-page__section main-page__offer">
            <h1 class="main-page__offer__title">Продаем линейку образовательных продуктов в виртуальной реальности</h1>
            <vr-button @click="openFeedbackModal" class="main-page__offer__btn" icon iconPosition="left"
                ><span>Запросить <span>коммерческое</span> предложение</span>
            </vr-button>
        </section>
        <section class="main-page__products">
            <h1 class="main-page__products__title">Продукты</h1>
            <div class="main-page__products__cards">
                <vr-product-card
                    v-for="(product, idx) in products"
                    :key="`grid-item-${idx}`"
                    :product="product"
                    :dark="product.dark"
                ></vr-product-card>
            </div>
            <vr-button :to="{ name: 'products' }" class="main-page__products__btn" icon iconPosition="left" outlined
                >Все продукты</vr-button
            >
        </section>
        <section class="main-page__section main-page__services">
            <img
                class="main-page__services__img"
                src="@/assets/img/views/main/services.jpg"
                alt="Предоставляемые услуги"
            />
            <div class="main-page__services__info">
                <h1 class="main-page__services__info__title">Услуги</h1>
                <p class="main-page__services__info__description">
                    Поможем познакомиться с VR-технологией в образовании и подготовить Ваше образовательное учреждение к
                    использованию VR:
                </p>
                <ul class="main-page__services__info__list">
                    <li>проектирование VR-кабинетов</li>
                    <li>организация обучения</li>
                    <li>аренда оборудования</li>
                </ul>
                <vr-button to="/services" outlined class="main-page__services__info__btn">Подробнее</vr-button>
            </div>
        </section>
        <!-- <div class="main-page__news">
      <h1 class="main-page__news__title">Новости</h1>
      <vr-carousel
        class="main-page__news__carousel"
        v-if="mockNews && mockNews.length"
      >
        <news-card
          v-for="(news, id) in mockNews"
          :key="`news-${id}`"
          :news="news"
        />
      </vr-carousel>
      <vr-button class="main-page__news__btn" icon iconPosition="left" outlined
        >Все новости</vr-button
      >
    </div> -->
        <section class="main-page__section main-page__collage">
            <img src="@/assets/img/common/collage.jpg" alt="Коллаж 'о компании'" class="main-page__collage__image" />
        </section>
        <section class="main-page__section main-page__about">
            <h1 class="main-page__about__title">О компании</h1>
            <p>
                Центр компетенций НТИ в области технологии виртуальной и дополненной реальности создан на базе
                Дальневосточного федерального университета.
            </p>
            <p>
                В рамках направления «Образование» Центр исследует, разрабатывает и внедряет инновационные решения с
                применением технологий VR/AR для повышения качества образования, разрабатывает программы повышения
                квалификации для педагогов, готовит обучающие материалы для них и занимается развитием сообщества
                преподавателей.
            </p>
            <vr-button :to="{ name: 'about' }" class="main-page__about__btn" outlined>Подробнее</vr-button>
        </section>
    </div>
</template>

<script>
// TODO: add news
import VRSlider from '@/components/VRSlider.vue';
import VRSliderCard from '@/components/cards/VRSliderCard.vue';
import VRButton from '@/components/buttons/VRButton.vue';
// import VRCarousel from "@/components/VRCarousel.vue";
import VRProductCard from '@/components/cards/VRProductCard.vue';
// import NewsCard from "@/components/NewsCard.vue";
import productsMixin from '@/mixins/products.mixin.js';
import FeedbackModal from '@/components/modals/FeedbackModal.vue';

export default {
    name: 'index',
    mixins: [productsMixin],
    methods: {
        openFeedbackModal() {
            this.$refs.feedbackModal.showModal();
        },
    },

    components: {
        'vr-slider': VRSlider,
        'vr-slider-card': VRSliderCard,
        'vr-button': VRButton,
        // "vr-carousel": VRCarousel,
        'vr-product-card': VRProductCard,
        // "news-card": NewsCard,
        'feedback-modal': FeedbackModal,
    },
};
</script>
