export default {
    data() {
        return {
            routes: [
                {
                    to: '/',
                    label: 'Главная',
                },
                {
                    to: '/products',
                    label: 'Продукты',
                },
                {
                    to: '/services',
                    label: 'Услуги',
                },
                {
                    to: '/about',
                    label: 'О компании',
                },
            ],
        };
    },
};
