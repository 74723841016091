<template>
    <button
        @click="handleClick($event)"
        :disabled="disabled"
        :class="[
            'vr-button',
            `vr-button--${size}`,
            { 'vr-button--outlined': outlined },
            { 'vr-button--disabled': disabled },
            { 'vr-button--icon-left': iconPosition === 'left' },
            { 'vr-button--fullwidth': fullWidth },
        ]"
    >
        <slot />
        <component v-if="icon" class="vr-button__icon" :is="chevron" />
    </button>
</template>

<script>
import SolidIcon from 'icons/ChevronRight';
import OutlinedIcon from 'icons/ChevronRightCircle';

export default {
    props: {
        // possible values: small, large
        size: {
            type: String,
            default: 'large',
        },
        icon: {
            type: Boolean,
            default: false,
        },
        iconPosition: {
            type: String,
            default: 'right',
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        to: {
            type: [String, Object],
            default: null,
        },
        type: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            defaul: false,
        },
    },
    methods: {
        handleClick(ev) {
            if (this.to) this.$router.push(this.to);
            else this.$emit('click', ev);
        },
    },
    computed: {
        chevron() {
            return this.outlined ? OutlinedIcon : SolidIcon;
        },
    },
};
</script>
