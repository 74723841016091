<template>
    <div v-if="show" class="cookies-modal">
        <h4>Cookies</h4>
        <p>
            Этот сайт использует cookies для хранения данных. Продолжая использовать сайт, вы даете согласие на работу с
            этими файлами.
        </p>
        <vr-button @click="accept" size="small" outlined>Принять</vr-button>
    </div>
</template>

<script>
import VRButton from '@/components/buttons/VRButton.vue';

export default {
    data() {
        return {
            show: null,
        };
    },
    components: {
        'vr-button': VRButton,
    },
    methods: {
        accept() {
            localStorage.setItem('cookiesAlertAccepted', true);
            this.show = false;
        },
    },
    mounted() {
        this.show = localStorage.getItem('cookiesAlertAccepted') ? false: true;
    },
};
</script>
