<template>
    <div class="vr-large-product-card" :style="coverUrl">
        <router-link
            :to="{name: product.pathName}"
            :class="{
                'vr-large-product-card__container': true,
                'vr-large-product-card__container--dark': dark,
            }"
        >
            <h5 class="vr-large-product-card__container__title">
                {{ product.name }}
            </h5>
            <p class="vr-large-product-card__container__text">
                {{ product.text }}
            </p>
            <span class="vr-large-product-card__container__span"> Узнать больше <chevron-down /></span>
        </router-link>
    </div>
</template>

<script>
import ChevronDown from 'icons/ChevronDown';

export default {
    props: {
        product: {
            type: Object,
            default: () => ({
                name: '',
                text: '',
                desktopCoverUrl: '',
                pathName: '#',
            }),
            required: true,
        },
        dark: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ChevronDown,
    },
    computed: {
        coverUrl() {
            return { backgroundImage: `url(${this.product.desktopCoverUrl})` };
        },
    },
};
</script>
