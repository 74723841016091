var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
		'vr-checkbox',
		{ 'vr-checkbox--error': _vm.error },
		{ 'vr-checkbox--required': _vm.required },
		{ 'vr-checkbox--active': _vm.active },
		{ 'vr-checkbox--filled': _vm.filled },
	]},[_c('input',{staticClass:"vr-checkbox__input",attrs:{"id":_vm.inputId,"type":"checkbox"},domProps:{"checked":_vm.localValue},on:{"input":function($event){return _vm.handleInput($event)}}}),_c('label',{attrs:{"for":_vm.inputId}},[_c('span',{staticClass:"vr-checkbox__label"},[_vm._v(_vm._s(_vm.placeholder))])]),_c('span',{staticClass:"vr-checkbox__message"},[_vm._v(_vm._s(_vm.errorMsg))])])
}
var staticRenderFns = []

export { render, staticRenderFns }