<template>
    <div id="app" class="page">
        <router-view name="navigation" />
        <router-view name="default" />
        <router-view name="footer" />
        <cookies-modal />
    </div>
</template>

<script>
import CookiesModal from '@/components/modals/CookiesModal.vue';

export default {
    name: 'App',

    components: { 'cookies-modal': CookiesModal },
    mounted() {
        this.$root.$on('showOverflow', () => {
            document.body.classList.add('body--overflow-hidden');
        });
        this.$root.$on('hideOverflow', () => {
            document.body.classList.remove('body--overflow-hidden');
        });
        this.$root.$on('toggleOverflow', () => {
            document.body.classList.toggle('body--overflow-hidden');
        });
    },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
@import '@/assets/css/scss/_index.scss';
</style>
