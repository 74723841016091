
import VRPlaceholder from "@/components/inputs/VRPlaceholder"

export default {
    data() {
        return {
            active: false,
            touched: false,
        };
    },
    props: {
        value: {
            type: [String, Array],
            default: null,
        },
        options: {
            type: Array,
            default: () => [],
        },
        required: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        // Elements in the array must be arrow functions
        // Each function takes input value as argument and must return true if rule was fullfilled or string message if it was broken
        // Example: rules: [value => !!value || 'required value', value !== 'error' || 'test error message'];
        rules: {
            type: Array,
            default: () => [],
        },
        setter: {
            type: Function,
            default: null,
        },
    },
    components: {
        'vr-placeholder': VRPlaceholder,
    },
    methods: {
        async handleInput(ev) {
            this.touched = true;
            const value = ev.target.value;
            this.localValue = null;
            await this.$nextTick();
            this.localValue = this.setter ? this.setter(value) : value;
        },
        handleClickOutside(ev) {
            if (!this.$el.contains(ev.target)) {
                this.active = false;
            }
        },
        resetLocalValue(){
            this.localValue = null;
        },
    },
    computed: {
        localValue: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit('input', v);
            },
        },
        filled() {
            return !!this.localValue;
        },
        errorMsg() {
            if (!this.touched) return '';
            if (this.required && !this.filled) return 'Требуется заполнить поле';

            const brokenRule = this.rules.find(rule => {
                return rule(this.value) !== true;
            });
            return brokenRule ? brokenRule(this.value) : '';
        },
        error() {
            return !!this.errorMsg;
        },
    },
    watch: {
        error() {
            this.$emit('change', this.error);
        }
    },
    mounted() {
        this.$root.$on('resetInput', () => {
            this.active = false;
            this.touched = false;
            this.resetLocalValue();
        });

        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleClickOutside);
    },
};
