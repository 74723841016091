<template>
    <div
        :class="[
            'vr-textarea',
            { 'vr-textarea--error': error },
            { 'vr-textarea--required': required },
            { 'vr-textarea--active': active },
            { 'vr-textarea--filled': filled },
        ]"
    >
        <textarea
            @focus="active = true"
            @blur="active = false"
            :value="localValue"
            @input="handleInput($event)"
            :rows="rows"
            class="vr-textarea__textarea"
        ></textarea>
        <vr-placeholder
            :required="required"
            :active="active"
            :filled="filled"
            :error="error"
            class="vr-textarea__placeholder"
            >{{ placeholder }}</vr-placeholder
        >
        <span class="vr-textarea__message">{{ errorMsg }}</span>
    </div>
</template>

<script>
import inputMixin from '@/mixins/input.mixin.js';

export default {
    mixins: [inputMixin],
    props: {
        rows: {
            type: String,
            default: '3',
        },
    },
};
</script>
