<template>
    <div id="footer" class="footer">
        <div class="footer__content">
            <router-link :to="{ name: 'index' }">
                <img class="footer__content__logo" src="@/assets/img/logo/logo-nti.png" />
            </router-link>
            <div class="footer__content__navigation-content">
                <router-link
                    v-for="(route, idx) in routes"
                    :key="`route-${idx}}`"
                    :to="route.to"
                    class="footer__content__navigation-content__link"
                    >{{ route.label }}</router-link
                >
            </div>

            <div class="footer__content__contacts-content">
                <span class="footer__content__contacts-content__title">Контакты</span>
                <a class="footer__content__contacts-content__email" href="mailto:eduvr@vrnti.ru">
                    <span class="footer__content__contacts-content__email__text">eduvr@vrnti.ru</span>
                </a>
                <div class="footer__content__contacts-content__social">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://vk.com/educationvrar"
                        class="footer__content__contacts-content__social__link"
                    >
                        <img src="@/assets/img/logo/vk-logo-dark.png" alt="логотип вконтакте" />
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://t.me/virtualliteracy01"
                        class="footer__content__contacts-content__social__link"
                    >
                        <img src="@/assets/img/logo/telegram-logo-dark.png" alt="логотип телеграм" />
                    </a>
                </div>
            </div>
            <div class="footer__content__legal">
                <span class="footer__content__legal__copyright"
                    >&copy; {{ new Date().getFullYear() }} Центр НТИ ДВФУ VR/AR</span
                >
                <router-link :to="{ name: 'privacy' }" class="footer__content__legal__privacy"
                    >Конфиденциальность</router-link
                >
            </div>
        </div>
    </div>
</template>

<script>
import routerMixin from '@/mixins/router.mixin.js';

export default {
    mixins: [routerMixin],
};
</script>
