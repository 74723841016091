var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'vr-input',
        { 'vr-input--error': _vm.error },
        { 'vr-input--required': _vm.required },
        { 'vr-input--active': _vm.active },
        { 'vr-input--filled': _vm.filled },
    ]},[_c('input',{staticClass:"vr-input__input",attrs:{"id":_vm.inputId,"type":_vm.type,"list":`${_vm.inputId}-datalist`},domProps:{"value":_vm.localValue},on:{"focus":function($event){_vm.active = true},"blur":function($event){_vm.active = false},"input":function($event){return _vm.handleInput($event)}}}),_c('vr-placeholder',{staticClass:"vr-input__placeholder",attrs:{"required":_vm.required,"active":_vm.active,"filled":_vm.filled,"error":_vm.error}},[_vm._v(_vm._s(_vm.placeholder))]),_c('span',{staticClass:"vr-input__message"},[_vm._v(_vm._s(_vm.errorMsg))]),(_vm.options)?_c('datalist',{staticClass:"vr-input__datalist",attrs:{"id":`${_vm.inputId}-datalist`}},_vm._l((_vm.options),function(option,idx){return _c('option',{key:`option-${idx}`,domProps:{"value":option}})}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }