<template>
	<div
		:class="[
			'vr-checkbox',
			{ 'vr-checkbox--error': error },
			{ 'vr-checkbox--required': required },
			{ 'vr-checkbox--active': active },
			{ 'vr-checkbox--filled': filled },
		]"
	>
		<input
			:id="inputId"
			:checked="localValue"
			@input="handleInput($event)"
			type="checkbox"
			class="vr-checkbox__input"
		/>
		<label :for="inputId">
			<span class="vr-checkbox__label">{{ placeholder }}</span>
		</label>
		<span class="vr-checkbox__message">{{ errorMsg }}</span>
	</div>
</template>

<script>
import { nanoid } from 'nanoid/non-secure';
import inputMixin from '@/mixins/input.mixin';

export default {
	mixins: [inputMixin],
	props: {
		value: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			inputId: `input-${nanoid()}`,
		};
	},
	methods: {
		async handleInput(ev) {
			this.touched = true;
			const value = ev.target.checked;
			this.localValue = null;
			await this.$nextTick();
			this.localValue = this.setter ? this.setter(value) : value;
		},
	},
};
</script>
