<template>
    <base-modal ref="baseModal" :size="size" :align="align">
        <div class="list-modal">
            <slot />
        </div>
    </base-modal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal.vue';

export default {
    props: {
        // possible values (width in brackets): small (800px), medium (1100px), large (1400px)
        size: {
            type: String,
            default: 'medium',
        }, // possible values: top, center
        align: {
            type: String,
            default: 'top',
        },
    },
    components: {
        'base-modal': BaseModal,
    },
    methods: {
        showModal() {
            this.$refs.baseModal.showModal();
        },
    },
};
</script>
