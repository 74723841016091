<template>
    <div @click="closeModalOnClick($event)" :class="{ 'base-modal': true, 'base-modal--display': show }">
        <div :class="['base-modal__wrapper', `base-modal__wrapper--align-${align}`]">
            <button class="base-modal__close-btn">
                <close-icon class="base-modal__close-btn__icon--desktop" /><close-circle-icon
                    class="base-modal__close-btn__icon--mobile"
                />
            </button>
            <div ref="modalContent" class="base-modal__content" :class="`base-modal__content--${size}`">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
import CloseIcon from 'icons/WindowClose';
import CloseCircleIcon from 'icons/CloseCircle';

export default {
    data() {
        return {
            show: false,
        };
    },
    components: {
        CloseIcon,
        CloseCircleIcon,
    },
    props: {
        // possible values (width in brackets): small (800px), medium (1100px), large (1400px)
        size: {
            type: String,
            default: 'medium',
        },
        // possible values: top, center
        align: {
            type: String,
            default: 'top'
        },
    },
    methods: {
        showModal() {
            this.show = true;
            this.$root.$emit('showOverflow');
        },
        closeModal() {
            this.show = false;
            this.$root.$emit('resetInput');
            this.$root.$emit('hideOverflow');
            this.$root.$emit('closeModal');
        },
        closeModalOnClick(ev) {
            if (!this.$refs.modalContent.contains(ev.target)) this.closeModal();
        },
    },
};
</script>
