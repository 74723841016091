const baseUrl = process.env.VUE_APP_API_URL;
const headers = {
	'Content-Type': 'application/json',
};

function post(url, data) {
	return fetch(url, { method: 'POST', headers, body: JSON.stringify(data) }).then(function (res) {
		if (!res.ok) {
			throw new Error('Network request error');
		} else {
			return res.json();
		}
	});
}

export function submitFeedback(form) {
	const data = {
		name: form.name.value,
		phone: form.phone.value,
		email: form.email.value,
		contact: form.contact.value,
		city: form.city.value,
		organisation: form.organisation.value,
		position: form.position.value,
		products: form.products.value.join(', '),
		required_demo: form.requiredDemo.value,
		equipment_type: form.equipmentType.value,
		equipment_num: form.equipmentNum.value,
		comments: form.comments.value,
	};

	return post(`${baseUrl}/feedback/`, data);
}
