var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:[
        'vr-placeholder',
        { 'vr-placeholder--required': _vm.required },
        { 'vr-placeholder--active': _vm.active },
        { 'vr-placeholder--filled': _vm.filled },
        { 'vr-placeholder--error': _vm.error },
    ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }