<template>
    <div id="navbar" class="navbar">
        <div class="navbar__content">
            <router-link :to="{name: 'index'}">
                <div class="navbar__content__logo">
                    <img class="navbar__content__logo__image" src="@/assets/img/logo/logo-nti.png" />
                </div>
            </router-link>

            <div class="navbar__content__links">
                <router-link
                    v-for="(route, idx) in routes"
                    :key="`route-${idx}}`"
                    :to="route.to"
                    class="navbar__content__links__link"
                    >{{ route.label }}</router-link
                >
            </div>

            <a class="navbar__content__email" href="mailto:eduvr@vrnti.ru">
                <email-icon class="navbar__content__email__icon" />
                <span class="navbar__content__email__text">eduvr@vrnti.ru</span>
            </a>

            <button class="navbar__content__mobile-menu-btn" @click="handleClick">
                <menu-icon v-if="!showMenu" />
                <close-icon v-else />
            </button>

            <div class="navbar__content__mobile-menu" :class="{ hidden: !showMenu }">
                <div @click="handleClick" class="navbar__content__mobile-menu__links">
                    <router-link
                        v-for="(route, idx) in routes"
                        :key="`route-${idx}}`"
                        :to="route.to"
                        class="navbar__content__links__link navbar__content__mobile-menu__links__link"
                        >{{ route.label }}</router-link
                    >
                    <a class="navbar__content__email navbar__content__mobile-menu__email" href="mailto:eduvr@vrnti.ru">
                        <email-icon class="email__icon" />
                        <span class="email__text">eduvr@vrnti.ru</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuIcon from 'icons/Menu';
import EmailIcon from 'icons/EmailOutline';
import CloseIcon from 'icons/WindowClose';
import routerMixin from '@/mixins/router.mixin.js';

export default {
    mixins: [routerMixin],
    data() {
        return {
            showMenu: false,
        };
    },
    components: {
        MenuIcon,
        EmailIcon,
        CloseIcon,
    },
    methods: {
        handleClick() {
            this.showMenu = !this.showMenu;
            this.$root.$emit('toggleOverflow');
        },
    },
};
</script>
