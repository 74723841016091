import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '@/views/Main.vue';
import Products from '@/views/Products.vue';
import About from '@/views/About.vue';
import Services from '@/views/Services.vue';
import VRChemLab from '@/views/products/VRChemLab.vue';
import VRSpace from '@/views/products/VRSpace.vue';
import Magnetism from '@/views/products/Magnetism.vue';
import Varvara from '@/views/products/Varvara.vue';
import Privacy from "@/views/Privacy.vue";

import Navbar from '@/components/Navbar.vue';
import Footbar from '@/components/Footbar.vue';

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            name: 'index',
            path: '/',
            components: {
                navigation: Navbar,
                default: Main,
                footer: Footbar,
            },
        },
        {
            name: 'products',
            path: '/products',
            components: {
                navigation: Navbar,
                default: Products,
                footer: Footbar,
            },
        },
        {
            name: 'about',
            path: '/about',
            components: {
                navigation: Navbar,
                default: About,
                footer: Footbar,
            },
        },
        {
            name: 'services',
            path: '/services',
            components: {
                navigation: Navbar,
                default: Services,
                footer: Footbar,
            },
        },
        {
            name: 'vr-chem-lab',
            path: '/vr-chem-lab',
            components: {
                navigation: Navbar,
                default: VRChemLab,
                footer: Footbar, 
            },
        },
        {
            name: 'vr-space',
            path: '/vr-space',
            components: {
                navigation: Navbar,
                default: VRSpace,
                footer: Footbar,
            },
        },
        {
            name: 'magnetism',
            path: '/magnetism',
            components: {
                navigation: Navbar,
                default: Magnetism,
                footer: Footbar,
            },
        },
        {
            name: 'varvara',
            path: '/varvara',
            components: {
                navigation: Navbar,
                default: Varvara,
                footer: Footbar,
            },
        },
        {
            name: 'privacy',
            path: '/privacy',
            components: {
                navigation: Navbar,
                default: Privacy,
                footer: Footbar,
            },
        }
    ],
});
